<template>
    <div class="home">
        <!-- 内容 -->
        <div class="contentBox">
            <!-- 为你推荐板块 -->
            <div class="recommendedW" v-show="isList">
                <div class="recommended">
                    <div class="showCircleList">
                        <div class="showCircleItem" v-for="(item, index) in arrs" :key="index">
                            <div class="videoAndImg" @mouseover="videoPlayback(index, item.video_address, -99)"
                                @mouseout="videoStopped(index, item.video_address, -99)" @click="toPlayers(item)">
                                <img class="showCircleItemImg"
                                    :src="item.video_address ? (item.video_address + '?vframe/jpg/offset/0') : item.imgs[0]"
                                    alt="" ref="goodsimg" />
                                <div class="video_box">
                                    <video id="video" ref="goodsVideo" width="100%" height="100%" @contextmenu.prevent
                                        controls controlslist="nodownload nofullscreen noremote footbar noplaybackrate"
                                        disablePictureInPicture muted="muted" style="display: none; object-fit: cover">
                                        <!-- :poster="item.thumb"> -->
                                        <source type="audio/mp4" :src="item.video_address" />
                                    </video>
                                </div>
                            </div>

                            <div class="showCircleItemInfo">
                                <div class="showCircleItemInfoTitle ellipsis">
                                    {{ item.title }}
                                </div>

                                <div class="touxiangAndName">
                                    <img class="touxiangImg" :src="item.user_info.avatar" alt="" />
                                    <div class="name">
                                        {{ item.user_info.nick_name }}
                                    </div>
                                </div>
                                <div class="transverse"></div>
                                <div class="showCircleItemSet">
                                    <div class="showCircleItemSetItem">
                                        <img class="showCircleItemSetIcon" src="../../assets/dianzanIcon.png" alt="" />
                                        <span>{{ item.like }}</span>
                                    </div>
                                    <div class="showCircleItemSetItem">
                                        <img class="showCircleItemSetIcon" src="../../assets/pinglunIcon.png" alt="" />
                                        <span>{{ item.comment_num }}</span>
                                    </div>
                                    <div class="showCircleItemSetItem">
                                        <img class="showCircleItemSetIcon" src="../../assets/zhuanfaIcon.png" alt="" />
                                        <span>{{ item.retweet_num }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <infinite-loading spinner="spiral" ref="infiniteLoading" @infinite="infiniteHandler" :distance="200"
                        class="infinite-loading-wrap" style="color: #999999; font-size: 0.83333vw">
                        <span slot="no-more">到底了亲～</span>
                    </infinite-loading>
                </div>
            </div>

            <div class="recommendedW" v-show="!isList">
                <div class="searchNoResults">
                    <img src="../../assets/searchNoResults.png" alt="" />
                    <div>当前搜索无结果可以试试输入其他～</div>
                </div>
            </div>
        </div>

        <div class="flexd">
            <div class="flexdBox">
                <el-backtop>
                    <img style="width: 1.51042vw; height: 1.45833vw" src="../../assets/huiding.png" alt="" />
                </el-backtop>
            </div>
        </div>

        <el-dialog :visible.sync="dialogVisible" :show-close="false" width="30%">
            <div class="feedbackDialog">
                <div class="feedbackDialogTop">
                    <div class="close"></div>
                    <div class="title">用户反馈</div>
                    <div class="close">
                        <img src="../../assets/closeIcon.png" alt="" />
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
import bannerVideo from "@/assets/video7.mp4";
import bannerVideo2 from "@/assets/video7.mp4";

import {
    getVideoList,
    getGoodsList,
} from "../../utils/api.js";



export default {
    components: {
    },

    data() {
        return {
            options: {
                // 替换成你自己项目的 App ID。
                appId: "84ff6fab3c894b70ba847e2bcd46b3fe",
                // 传入目标频道名。
                channel: "",
                // 如果你的项目开启了 App 证书进行 Token 鉴权，这里填写生成的 Token 值。
                token: null,
                // 设置频道内的用户角色，可设为 "audience" 或 "host"
                role: "audience",
                uid:
                    ("1" + `${+new Date()}`.substring(`${+new Date()}`.length - 8)) * 1,
            },
            bannerList: [],
            videoTime: "",
            timer: "",

            videoArr: [],
            list: [],
            page: {
                page_limit: 9,
                page_num: 1,
            },
            pages: {
                page_limit: 10,
                page_num: 1,
            },
            showCircleIndex: 1,
            count: 0,
            arr: [],
            arrs: [],
            dialogVisible: false,
            loading: false,

            liveList: [],
            userUid: "",
            bannerVideo: bannerVideo,
            bannerVideo2: bannerVideo2,
            actionIndex: 0,
            isautoplay: true,

            w: '100%',
            h: '300px',

            totalPage: 1,
            keyword: '',

            isList: true,

        };
    },
    created() {
    },
    beforeMount() {
        window.parentMounted = this._isMounted; // _isMounted是当前实例mouned()是否执行 此时为false
    },

    mounted() {
        this.getVideoLists();
    },

    watch: {
        $route(to, from) {
            if (to.query.keyword != from.query.keyword) {
                this.keyword = to.query.keyword;
                this.pages.page_num = 1;
                this.arrs = [];
                this.arrVideoList();
                // this.state.reset();
            }
        },
    },
    methods: {

        arrVideoList($state) {
            this.keyword = this.$route.query.keyword;
            let data = {
                full_fields: [
                    "user_info",
                    "is_like",
                    "goods_info",
                    "type_info",
                    "is_thumb",
                ],
                map: {
                    desc: this.keyword ? this.keyword : null
                },
                page: this.pages,
            }
            if (!this.keyword) {
                delete data.map.desc
            }
            getVideoList(data).then((res) => {
                window.parentMounted = this._isMounted; // _isMounted是当前实例mouned()是否执行 此时为true
                if (res.data.length) {
                    if (this.arrs.length < res.page.data_count) {
                        res.data.map(item => {
                            item.page_num = this.pages.page_num
                        })
                        this.pages.page_num++;
                        this.arrs = this.arrs.concat(res.data);
                        $state.loaded();
                    } else {

                        $state.complete();
                    }
                } else {
                    $state.complete();
                }
                if (res.page.data_count) {
                    this.isList = true;
                } else {
                    this.isList = false;
                }
            });
        },

        //  秀圈
        getVideoLists() {
            // console.log(this.$API.);
            this.keyword = this.$route.query.keyword;
            let data = {
                full_fields: [
                    "user_info",
                    "is_like",
                    "goods_info",
                    "type_info",
                    "is_thumb",
                ],
                map: {
                    desc: this.keyword ? this.keyword : null
                },
                page: this.page,
            }
            if (!this.keyword) {
                delete data.map.desc
            }
            getVideoList(data).then((res) => {
                if (res.code == 1) {
                    // let arr = this.deepClone(res.data);
                    // console.log(arr);
                    res.data.map(item => {
                        item.page_num = this.page.page_num
                    })
                    this.count = res.page.data_count;
                    // let videoArr = res.data
                    if (this.page.page_num != 1) {
                        this.arr = this.arr.concat(res.data);
                    } else {
                        this.arr = res.data;
                    }

                    let newDataList = [];
                    let current = 0;
                    if (this.arr && this.arr.length > 0) {
                        for (let i = 0; i <= this.arr.length - 1; i++) {
                            if (i % 3 !== 0 || i === 0) {
                                //数据处理成几张展示
                                if (!newDataList[current]) {
                                    newDataList.push([this.arr[i]]);
                                } else {
                                    newDataList[current].push(this.arr[i]);
                                }
                            } else {
                                current++;
                                newDataList.push([this.arr[i]]);
                            }
                        }
                    }
                    this.videoArr = [...newDataList];
                    let timer = setInterval(() => {
                        let video = this.$refs.showCircleVideo;

                        let minutes = parseInt(video.duration / 60); // 分
                        let seconds = parseInt(video.duration % 60); // 秒
                        // minutes >= 10 ? minutes = minutes : minutes = "0" + minutes
                        // seconds >= 10 ? seconds = seconds : seconds = "0" + seconds
                        this.videoTime = minutes + ":" + seconds;
                        clearInterval(timer);
                    }, 200);
                }
                // this.videoArr = res.data
            });
        },
        changeShowCircle(e) {
            this.showCircleIndex = e + 1;
            // if (this.showCircleIndex * this.page.page_limit < this.count) {
            //     this.page.page_num++
            //     this.getVideoLists()
            // }
            if (this.showCircleIndex * 3 == this.arr.length - 3) {
                this.page.page_num++;
                this.getVideoLists();
            }
        },

        //深拷贝
        deepClone(obj) {
            let o = {};
            for (let key in obj) {
                o[key] = obj[key];
            }
            return o;
        },

        byEvents() {
            let newDataList = [];
            let current = 0;
            if (this.videoArr && this.videoArr.length > 0) {
                for (let i = 0; i <= this.videoArr.length - 1; i++) {
                    if (i % 3 !== 0 || i === 0) {
                        //数据处理成几张展示
                        if (!newDataList[current]) {
                            newDataList.push([this.videoArr[i]]);
                        } else {
                            newDataList[current].push(this.videoArr[i]);
                        }
                    } else {
                        current++;
                        newDataList.push([this.videoArr[i]]);
                    }
                }
            }
            this.videoArr = [...newDataList];
        },

        infiniteHandler($state) {
            // getGoodsList({
            //   page: this.pages,
            // }).then((res) => {
            //   window.parentMounted = this._isMounted; // _isMounted是当前实例mouned()是否执行 此时为true
            //   if (res.data.length) {
            //     this.pages.page_num++;
            //     this.list = this.list.concat(res.data);
            //     $state.loaded();
            //     // this.$refs.infiniteLoading.$emit('$InfiniteLoading:loaded');
            //     if (this.list.length / 20 === 10) {
            //       // this.$refs.infiniteLoading.$emit('$InfiniteLoading:complete');
            //     }
            //   } else {
            //     // this.$refs.infiniteLoading.$emit('$InfiniteLoading:complete');
            //     $state.complete();
            //   }
            // });
            this.arrVideoList($state);
        },

        //  请求商品列表
        getGoodsLists() {
            getGoodsList({
                page: this.pages,
            }).then((res) => {
                if (this.page.page_num != 1) {
                    this.pages.page_num++;
                    this.list = this.list.concat(res.data);
                    this.$refs.infiniteLoading.$emit("$InfiniteLoading:loaded");
                } else {
                    this.$refs.infiniteLoading.$emit("$InfiniteLoading:complete");
                    this.list = res.data;
                    //上拉加载数据结束
                }
            });
        },
        changeBanner(e) {
            this.videoStopped(this.actionIndex, bannerVideo, -66);

            this.actionIndex = e;
        },

        setTimeoutBannerVideo(index, video_address, i) {
            // setTimeout(() => {
            this.videoPlayback(this.actionIndex, video_address, i);
            // }, 500);
        },

        videoPlayback(index, video_address, i) {
            // i 是父下标
            // index 是子下标
            var video = null;
            var img = null;
            if (i == -10) {
                video = this.$refs.showCircleVideo;
                img = this.$refs.showCircleVideoImg;
            } else if (i == -99) {
                video = this.$refs.goodsVideo[index];
                img = this.$refs.goodsimg[index];
            } else if (i == -66) {
                // if (index == 0) {
                video = this.$refs.bannervideo;
                img = this.$refs.bannerVideoImg;
                this.isautoplay = false;
                // }
            } else {
                video = this.$refs.video[index];
                img = this.$refs.img[index];
            }
            if (video_address) {
                // 显示播放器
                video.style.display = "block";
                // 隐藏图片
                // video.style.display = "none";
                img.style.display = "none";

                // 定时器
                this.timer = setTimeout(() => {
                    // 播放视频
                    video.play();
                    // 给视频标签添加缓存播放---video标签属性。
                    video.setAttribute("autoplay", "autoplay");
                    // 给视频标签添加循环播放---video标签属性。
                    video.setAttribute("loop", "loop");
                    // 初始播放音量
                    video.volume = 0;
                }, 200);
                // 视频当前的播放时间(进度)
                // setInterval(() => {
                //     console.log(video.currentTime);
                // }, 0);
                // 获取视频时长
                // console.log(video.duration % 60);
                // let minutes = parseInt(video.duration / 60); // 分
                // let seconds = parseInt(video.duration % 60); // 秒
                // minutes >= 10 ? minutes = minutes : minutes = "0" + minutes
                // seconds >= 10 ? seconds = seconds : seconds = "0" + seconds
                // this.videoTime = minutes + ":" + seconds;
                // console.log(this.videoTime);
            }
        },
        videoStopped(index, video_address, i) {
            var video = null;
            var img = null;

            if (i == -10) {
                video = this.$refs.showCircleVideo;
                img = this.$refs.showCircleVideoImg;
            } else if (i == -99) {
                video = this.$refs.goodsVideo[index];
                img = this.$refs.goodsimg[index];
            } else if (i == -66) {
                video = this.$refs.bannervideo;
                img = this.$refs.bannerVideoImg;
                this.isautoplay = true;
            } else {
                video = this.$refs.video[index];
                img = this.$refs.img[index];
            }

            if (video_address) {
                // 清除定时器
                clearTimeout(this.timer);
                //停止播放
                video.pause();
                // 重置播放时间
                video.currentTime = 0;
                // 隐藏播放器
                // video.style.display = "none";
                // 隐藏图片
                img.style.display = "block";
            }
        },
        toPlayer(item) {
            this.$router.push({
                path: "Player",
                query: { uuid: item.uuid, page_num: item.page_num, totalPage: this.totalPage },
            });
        },

        toPlayers(item) {
            this.$router.push({
                path: "Player",
                query: { uuid: item.uuid, page_num: item.page_num, totalPage: this.totalPage, keyword: this.keyword },
            });
        },

        jumpUrl(url) {
            this.$router.push({
                path: url,
            });
        },
    },
};
</script>
  
<style lang="less" scoped>
.liveChannelW {
    background: rgba(238, 238, 238, 0.3);

    .liveChannel {
        display: flex;
        justify-content: space-between;
        margin: auto;
        width: 1140px;
        padding: 150px 140px 110px;

        .liveChannelLeft {
            width: 338px;
            padding-top: 60px;

            .liveChannelLeftTitle {
                margin-bottom: 50px;

                .title_en {
                    margin-bottom: 10px;
                    font-size: 26px;
                    font-weight: 600;
                    color: #666666;
                    letter-spacing: 10px;
                }

                .title_zh-cn {
                    font-size: 26px;
                    font-weight: 900;
                    color: #ff2442;
                    letter-spacing: 13px;
                }
            }

            .liveChannelLeftIntroduction {
                margin-bottom: 98px;
                text-align: justify;
                font-size: 14px;
                font-weight: 500;
                color: #999999;
            }

            .liveChannelLeftFindMore {
                cursor: pointer;
                width: 170px;
                height: 50px;

                .findMoreIcon {
                    width: 170px;
                    height: 50px;
                }
            }
        }

        .liveChannelRight {
            position: relative;
            width: 724px;
            height: 505px;
            background: #e6e6e6;
            border-radius: 11px;

            .liveChannelRightImg {
                position: absolute;
                top: -31px;
                right: 35px;
                width: 724px;
                height: 500px;
                border-radius: 11px;
                overflow: hidden;

                .tips {
                    font-size: 30px;
                    position: absolute;
                    bottom: 50px;
                    color: #66b1ff;
                    left: 50%;
                    transform: translate(-50%, 0);
                }

                img {
                    width: 724px;
                    height: 500px;
                    border-radius: 11px;
                }
            }
        }
    }
}

.showCircleW {
    .showCircle {
        padding: 100px 150px 120px 120px;
        width: 1150px;
        margin: auto;
        background-image: url("../../assets/showCircleBg.png");
        background-repeat: no-repeat;
        background-size: contain;

        .showCircleTop {
            display: flex;
            margin-bottom: 90px;

            .showCircleVideo {
                position: relative;
                margin-right: 75px;
                width: 705px;
                height: 442px;
                border-radius: 10px;

                .showCircleVideoImg {
                    width: 705px;
                    height: 442px;
                    object-fit: cover;
                    border-radius: 10px;
                }

                .showCircleVideovideo_box {
                    width: 705px;
                    height: 442px;
                    border-radius: 10px;
                    overflow: hidden;
                }

                .showCircleVideoImg {
                    position: absolute;
                }

                .showCircleVideoBottom {
                    box-sizing: border-box;
                    width: 100%;
                    position: absolute;
                    bottom: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 37px;

                    .showCircleVideoBottomLeft {
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        color: #ffffff;

                        img {
                            width: 24px;
                            height: 21px;
                            margin-right: 13px;
                        }
                    }

                    .showCircleVideoBottomRight {
                        padding: 0 10px;
                        line-height: 0.4rem;
                        border-radius: 4px;
                        font-size: 15px;
                        color: #ffffff;
                        height: 40px;
                        background: rgba(0, 0, 0, 0.4);
                    }
                }
            }

            .showCircleInfo {
                padding-top: 26px;

                .showCircleTitle {
                    margin-bottom: 30px;

                    .title_en {
                        margin-bottom: 10px;
                        font-size: 26px;
                        font-weight: 600;
                        color: #666666;
                        letter-spacing: 10px;
                    }

                    .title_zh-cn {
                        font-size: 26px;
                        font-weight: 900;
                        color: #ff2442;
                        letter-spacing: 13px;
                    }
                }

                .showCircleInfoIntroduction {
                    margin-bottom: 147px;
                    text-align: justify;
                    font-size: 14px;
                    font-weight: 500;
                    color: #999999;
                }

                .showCircleInfoBottom {
                    display: flex;
                    align-items: center;

                    .getInto {
                        cursor: pointer;
                        margin-right: 30px;
                        display: flex;
                        align-items: center;

                        .getIntoIcon {
                            width: 18px;
                            height: 18px;
                            margin-right: 11px;
                        }

                        span {
                            font-size: 18px;
                            color: #666666;
                        }
                    }

                    .showCircleInfoBottomNav {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 144px;
                        height: 20px;
                        font-size: 13px;
                        font-weight: 500;
                        color: #666666;

                        .line {
                            width: 1px;
                            height: 13px;
                            background: #666666;
                        }

                        .showCircleInfoBottomNavItem {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .showCircleListBox {
            display: flex;
            align-items: center;

            .showCircleList {
                flex: 1;
                padding-left: 258px;

                .el-carousel {
                    /deep/ .el-carousel__container {
                        height: 367px;

                        .el-carousel__item {
                            flex: 1;
                            display: flex;
                        }
                    }
                }

                .showCircleItem {
                    margin-right: 50px;
                    width: 241px;
                    height: 367px;
                    background: rgba(231, 202, 198, 0.3);
                    border-radius: 8px;

                    .videoAndImg {
                        position: relative;
                        width: 100%;
                        height: 186px;
                        border-radius: 4px;
                        overflow: hidden;
                        cursor: pointer;

                        img,
                        .video_box {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        img {
                            // 解决图片底部默认空白缝隙问题
                            display: block;
                            position: absolute;
                        }
                    }

                    .showCircleItemImg {
                        width: 241px;
                        height: 186px;
                        border-radius: 8px 8px 0 0;
                    }

                    .showCircleItemInfo {
                        padding: 10px 15px 18px;
                        height: 181px;

                        .showCircleItemInfoTitle {
                            font-size: 14px;
                            color: #666666;
                            margin-bottom: 10px;
                        }

                        .touxiangAndName {
                            display: flex;
                            align-items: center;
                            margin-bottom: 18px;

                            .touxiangImg {
                                width: 15px;
                                height: 15px;
                                margin-right: 6px;
                            }

                            .name {
                                font-size: 13px;
                                font-weight: 400;
                                color: #999999;
                            }
                        }

                        .transverse {
                            margin-bottom: 21px;
                            height: 1px;
                            opacity: 0.8;
                            border: 1px solid #e6e6e6;
                        }

                        .showCircleItemSet {
                            display: flex;
                            justify-content: space-between;
                            padding: 0 25px;

                            .showCircleItemSetItem {
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                .showCircleItemSetIcon {
                                    margin-bottom: 9px;
                                    width: 26px;
                                    height: 22px;
                                }

                                span {
                                    font-size: 13px;
                                    color: #666666;
                                }
                            }
                        }
                    }
                }
            }

            .switchPage {
                .switchPageBtn {
                    display: flex;
                    width: 41px;
                    height: 41px;
                    cursor: pointer;

                    .switchPageBtnImg {
                        width: 41px;
                        height: 41px;
                    }
                }
            }
        }
    }
}

.recommendedW {
    background: rgba(238, 238, 238, 0.3);

    .recommended {
        margin: auto;
        // width: 985px;
        width: 1185px;
        padding: 100px 210px;

        .recommendedTitle {
            margin-bottom: 50px;

            .title_en {
                text-align: center;
                margin-bottom: 6px;
                font-size: 26px;
                font-weight: 600;
                color: #666666;
                letter-spacing: 10px;
            }

            .title_zh-cn {
                text-align: center;
                font-size: 26px;
                font-weight: 900;
                color: #ff2442;
                letter-spacing: 13px;
            }
        }

        .showCircleList {
            display: flex;
            flex-wrap: wrap;
        }

        .showCircleItem {
            margin-right: 50px;
            margin-bottom: 20px;
            width: 241px;
            height: 367px;
            background: rgba(231, 202, 198, 0.3);
            border-radius: 8px;

            .videoAndImg {
                position: relative;
                width: 100%;
                height: 186px;
                border-radius: 4px;
                overflow: hidden;
                cursor: pointer;

                img,
                .video_box {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                img {
                    // 解决图片底部默认空白缝隙问题
                    display: block;
                    position: absolute;
                }
            }

            .showCircleItemImg {
                width: 241px;
                height: 186px;
                border-radius: 8px 8px 0 0;
            }

            .showCircleItemInfo {
                padding: 10px 15px 18px;
                height: 181px;

                .showCircleItemInfoTitle {
                    font-size: 14px;
                    color: #666666;
                    margin-bottom: 10px;
                }

                .touxiangAndName {
                    display: flex;
                    align-items: center;
                    margin-bottom: 18px;

                    .touxiangImg {
                        width: 15px;
                        height: 15px;
                        margin-right: 6px;
                    }

                    .name {
                        font-size: 13px;
                        font-weight: 400;
                        color: #999999;
                    }
                }

                .transverse {
                    margin-bottom: 21px;
                    height: 1px;
                    opacity: 0.8;
                    border: 1px solid #e6e6e6;
                }

                .showCircleItemSet {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 25px;

                    .showCircleItemSetItem {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .showCircleItemSetIcon {
                            margin-bottom: 9px;
                            width: 26px;
                            height: 22px;
                        }

                        span {
                            font-size: 13px;
                            color: #666666;
                        }
                    }
                }
            }
        }
    }
}

// 播放器样式
video::-webkit-media-controls-timeline {
    // 进度条
    width: 100%;
    padding: 0;
}

//观看的当前时间
video::-webkit-media-controls-current-time-display {
    display: none !important;
}

//剩余时间
video::-webkit-media-controls-time-remaining-display {
    display: none !important;
}

//音量按钮
video::-webkit-media-controls-mute-button {
    display: none !important;
}

video::-webkit-media-controls-toggle-closed-captions-button {
    display: none !important;
}

//音量的控制条
video::-webkit-media-controls-volume-slider {
    display: none !important;
}

// 全屏按钮
video::-webkit-media-controls-fullscreen-button {
    display: none !important;
}

//播放按钮
video::-webkit-media-controls-play-button {
    display: none !important;
}

// 播放控件
video::-webkit-media-controls {
    // 进度条
    opacity: 1;
    // 让鼠标指针一直显示
    cursor: pointer !important;
}

.flexd {
    position: fixed;
    top: 50%;
    right: 0;
    z-index: 99;

    .QRloadDown {
        margin-bottom: 60px;
        padding: 13px 11px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 4px;

        img {
            display: flex;
            width: 61px;
            height: 61px;
            margin-bottom: 10px;
        }

        .text {
            font-size: 10px;
            color: #ffffff;
        }
    }

    .el-backtop {
        padding: 19px 28px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 4px;

        img {
            cursor: pointer;
        }
    }

    .el-backtop {
        position: static;
        // background: none;
        // box-shadow: none;
    }
}

.playerW {
    display: flex;
    margin: 0 auto;
    width: 724px;
    height: 500px;

    .playerWBanner {
        position: relative;
        width: 724px;
        height: 500px;

        &:hover .playBox {
            display: flex;
            z-index: 99;
        }

        .playBox {
            cursor: pointer;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 337px;
            height: 81px;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 41px;
            display: none;
            align-items: center;
            justify-content: center;
            z-index: 9;

            &:hover {
                border: 1px solid #ffffff;
            }

            .livePlayIocn {
                width: 29px !important;
                height: 22px !important;
                margin-right: 16px;
            }

            .livePlayText {
                font-size: 24px;
                color: #ffffff;
            }
        }
    }
}

#player {
    z-index: 9;
    position: absolute;
    top: 0;
    left: 0;
    margin-right: 20px;
    width: 724px;
    height: 500px;
    border-radius: 10px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    /deep/ div {
        background-color: transparent !important;
    }

    /deep/ video {
        object-fit: contain !important;

        &~div {
            background-color: transparent !important;
        }
    }
}

.video-backgroundW {
    position: absolute;
    width: 724px;
    height: 500px;
    overflow: hidden;
}

#video-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    filter: blur(50px);
}

#playerBg {
    position: absolute;
    top: 0;
    left: 0;
    margin-right: 20px;
    width: 724px;
    height: 500px;
    background: #000;
    border-radius: 10px;

    /deep/ video {
        width: 100%;
        height: 100%;
        object-fit: cover !important;
    }
}

.searchNoResults {
      padding: 100px 0;
      text-align: center;
      img {
        width: 156px;
        height: 156px;
        margin-bottom: 50px;
      }
      div {
        font-size: 24px;
        font-weight: 500;
        color: #666666;
      }
    }

/deep/ .el-carousel__indicators {
    line-height: 0;
}
</style>