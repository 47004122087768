<template>
  <div style="position: relative">
    <div class="tjm_video">
      <video
        id="video"
        x5-video-orientation="portraint"
        x5-video-player-fullscreen="true"
        webkit-playsinline="true"
        autoplay
        preload
      >
        <!-- <source type="video/mp4" src="./video.mp4" /> -->
        <!-- <source src="./video2.webm" type="video/ogg"> -->
        <!-- <source src="./video2.webm" type="video/mp4"> -->
        <!-- <source src="./video2.webm" type="video/webm"> -->
      </video>
      <div class="copyVideo">
        <div class="tjm_text">天街喵天街喵天街喵</div>
        <!-- <div class="tjm_text2">天街喵天街喵天街喵</div> -->
      </div>
    </div>
    <div @click="play">播放</div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  methods:{
    play(){
    var audioEl = document.getElementById("video");
    audioEl.play(); // iOS 7/8 仅需要 play 一下

    }
  },
  mounted() {
    var timer = null;
    var audioEl = document.getElementById("video");
    let tjm_text = document.getElementsByClassName("tjm_text");
    // let tjm_text2 = document.getElementsByClassName("tjm_text2");
      // audioEl.load(); // iOS 9   还需要额外的 load 一下, 否则直接 play 无效
      // audioEl.play(); // iOS 7/8 仅需要 play 一下

      // setTimeout(() => {
      //   audioEl.muted = false
      // }, 3000);
    // }
    console.log(window.location);
    let _url = window.location.href;
    let url = decodeURIComponent(_url); //对传过来的参数解码
    let url1 = url.slice(33); //删掉参数前面的？
    console.log(url1);
    // window.addEventListener("ready", forceSafariPlayAudio, false);
    audioEl.addEventListener("playing", () => {
      //播放中
      console.log("播放中");
      timer = setInterval(() => {
        if (audioEl.currentTime >= 24) {
          tjm_text[0].style.opacity = 1;
          // tjm_text2[0].style.opacity = 1;
          tjm_text[0].innerHTML = url1;
        } else {
          tjm_text[0].style.opacity = 0;
          // tjm_text2[0].style.opacity = 0;
        }
        console.log("播放进度:" + parseFloat(audioEl.currentTime));
      }, 1000);
    });
    audioEl.addEventListener("pause", () => {
      //暂停开始执行的函数
      console.log("暂停播放");
      clearInterval(timer);
    });
    audioEl.addEventListener(
      "ended",
      function () {
        //结束
        clearInterval(timer);
        console.log("播放结束");
      },
      false
    );
  },
};
</script>


<style>
  /* @font-face{
    font-family: 'huangyou';
    src: url('./myFont.ttf');
  } */
#video {
  width: auto;
  height: auto;
  margin: auto;
  display: block;
}
.tjm_video {
  position: relative;
  width: fit-content;
    height: auto;
    margin: auto;
    display: block;
}

.tjm_text {
  position: absolute;
  left: 32%;
  top: 15%;
  font-size: 60px;
  color: rgb(248, 176, 80);
  font-family: "huangyou";

  /* display: none; */
  opacity: 0;
  transition: all 5s;
  -moz-transition: all 5s;
  -webkit-transition: all 5s;
  -o-transition: all 5s;
}

.tjm_text2 {
  position: absolute;
  left: 400px;
  top: 200px;
  font-size: 60px;
  color: rgb(248, 176, 80);
  font-family: "huangyou";
  /* display: none; */
  opacity: 0;
}

.copyVideo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}
</style>
