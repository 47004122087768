<template>
    <!-- 内容 -->
    <div style="font-size: 1.04167vw; width: 62.5vw;margin: auto;">
        <p>&nbsp;&nbsp;天街喵用户协议</p>
    <p><br></p>
    <p><br></p>
    <p>欢迎您来到“天街喵”平台使用软件及相关服务！</p>
    <p>“天街喵”系指浙江添丽网络科技有限公司及其关联方（以下简称"添丽网络"或"我们"）合法拥有并运营的、标注名称为“天街喵”的客户端应用程序向您提供的产品与服务；《“天街喵”用户服务协议》（以下称“本协议”）是您（以下简称"您"或"用户"）与添丽网络就您使用“天街喵”软件，并获得“天街喵”软件提供的相关服务等事宜所订立的契约。
    </p>
    <p>请您在使用天街喵相关平台产品或者服务前仔细阅读本协议， <strong>特别是免除或限制您责任的条款以及争议解决条款等 </strong>，在充分理解并同意后使用相关产品或者服务
        。<strong>如您不同意本协议正文内容或《天街喵隐私权政策》中任何条款的约定，您应立即停止注册、使用天街喵的软件和服务 。</strong>
        一旦您开始使用天街喵任何产品或者服务，即表示您已充分理解并同意本协议的全部内容，本用户协议即成为您与添丽网络间具有约束力的法律文件。请您特别注意：</p>
    <p>1、基于国家法律法规发生变化及维护交易秩序、保护消费者权益需要，我们会不时地检查并更新用户协议，因此有关用户协议的内容会随之变化。您应当及时关注和了解本协议的修订情况，如果您不同意修订后的协议，请您立即停止使用天街喵相关平台
        ，否则即视为您同意并完全接受修订后的协议。</p>
    <p>2、本协议内容包括本协议正文、隐私权政策以及所有天街喵公开生效的适用于所有用户的各类服务条款及规则，该等条款与规则为本协议不可分割的组成部分，与本协议具有同等法律效力。</p>
    <p>一、定义</p>
    <p>天街喵：由浙江添丽网络科技有限公司实际运营和管理的、为与用户一起探索新生活模式需求而建立的，名为天街喵（域名为ppzx168.com.cn）的电子商务平台，其形式包括但不限于天街喵APP客户端、天街喵微信小程序及未来可能开发产生的其他形式，本协议中或称"本平台"。
    </p>
    <p>天街喵服务平台提供者：天街喵的互联网信息及软件技术服务提供者添丽网络科技有限公司，或简称"添丽网络"。</p>
    <p>第三方商家：天街喵平台引进商家为消费者提供商品/服务，该等第三方商家在本协议项下统称为"商家"。</p>
    <p>个人信息：以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</p>
    <p>个人敏感信息：指包括身份证件号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易信息、14岁以下（含）儿童信息等个人信息，本隐私权政策中个人敏感信息将以粗体进行显著标识。</p>
    <p>二、用户注册登录</p>
    <p>1.账户获取</p>
    <p>注册是指用户注册登录天街喵，按要求填写相关信息并确认同意履行相关用户协议的过程。用户因与商家进行交易、获取有偿服务等而发生的所有费用及相关税费由用户自行承担。</p>
    <p><strong>用户在注册成为天街喵用户时提供的信息应真实、完整、有效，并保证天街喵与商家可以通过该信息与用户本人进行联系。 同时，用户应在相关资料发生变更时及时更新注册信息。</strong></p>
    <p><strong>为给天街喵用户创建一个真实、海量、舒适的生活场景/购物环境，保障会员的人身及财产安全，同时维护商家交易安全，如用户使用模拟器或采取其他虚伪、作弊、欺诈手段完成注册，或在使用平台服务的过程中通过上述不正当手段进行交易、攫取权益的，我们有权对其账户采取如要求验证、账户管控、限制交易、收回权益等手段，或拒绝、中止向该用户提供服务，并有权向该用户就我们遭受的损失进行追偿。</strong>
    </p>
    <p>在成功注册后，天街喵平台会为每位用户开通一个会员账户。该账户是用户在使用天街喵软件及服务的重要身份标识，该账户的注册号码、昵称、密码和生物识别信息（如有）等，均由用户自行设置、保管、维护更新，用户应并对在其账户下发生的所有活动承担责任。
        <strong>我们原则上只允许每位用户使用一个天街喵账户，如有证据证明您存在不当注册或不当使用多个会员账户的情形，我们可采取冻结或关闭账户、取消订单、拒绝提供服务等处置措施 ，
            如您给我们或天街喵平台相关商品、服务提供方造成损失的，您应承担相应赔偿责任。</strong></p>
    <p><strong>同时，天街喵用户应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力，并以个人生活消费为目的使用天街喵服务。</strong>
        无民事行为能力人、限制民事行为能力人以及无经营或特定经营资格的组织不得注册为天街喵用户。对于不符合注册条件的用户，我们有权立即停止向该用户提供服务、注销登录账户，并由该用户自行承担由此产生的法律风险与责任。</p>
    <p>2.账户规范使用</p>
    <p>在您完成会员账户的注册并授权天街喵通过您的非现金支付工具（包括微信支付、支付宝等第三方支付工具）在天街喵平台进行消费结算后，会员账户与您的非现金支付工具是密切挂钩的。您知悉并同意，将您的会员账户交由他人使用可能会造成您本人在支付工具端产生债权债务纠纷、资信问题等不良影响；将您的非现金支付工具用于多个会员账户的付款同样可能给您本人、其他天街喵用户、平台商家造成人身或财产损失，因此我们特别请您不要将自己的会员账户给予他人使用或将自己的非现金支付工具提供给他人用于天街喵交易订单的付款。同一会员账户使用多个支付账户付款或同一支付账户向多个会员账户进行付款的行为将被天街喵系统识别为高风险交易，相关支付账户为风险支付账户，该会员账户也将被视为异常账户。
        <strong>为了保障广大会员的权益，我们有权采取拒绝异常账户的交易请求、取消风险订单、收回活动权益、终止其访问等措施进行风险管理。
            如您可以提供有效证据证明违法、违约、不诚信或滥用会员权利的情形并不成立的，或能够对账户风险、异常行为作出合理解释的，您有权通过客服热线进行申诉。
            您认可我们有权根据您的请求及相关账户行为以大众一般认知的知识水平标准对相关内容进行的合理判别，自行判断上述证据或解释是否成立，并对您的账户或交易行为进行下一步操作。</strong>同一用户，是指会员账户信息、支付账户、收货人姓名、详细地址、联系方式、IP地址、设备ID等与用户身份相关的信息，其中任意一项或数项存在相同、相似、通过特定标记形成批量信息、命中设备指纹等反作弊规则或存在其他非真实有效等情形的，均将被认定为同一用户，按本平台所有营销活动规则中的同一用户处理。
        <strong>同一用户重复参与优惠、促销、限购等规则明确不可重复参与的活动时，我们有权收回已发放的权益、取消相关订单并对该用户进行账号冻结、限制交易等违规处置。</strong>如您发现您的账户存在被他人使用的情况，请立即通知我们并可向公安机关报案，我们在收到您的通知后，在合理时间内将采取措施最大限度防止损失扩大。请您理解我们提供的服务是有限的，对于并非天街喵责任所导致的账户风险与损失，我们不会向您作出赔偿。从公平合理的角度出发，您应知晓所有通过上述完成注册及支付授权后的账户实现的操作，天街喵只能默认是您本人的行为，由此产生的后果由您自行承担。
    </p>
    <p>二、商品交易</p>
    <p>1.商品信息</p>
    <p><strong>随着天街喵上商品信息海量，由于模式不同，线上商品与对应实体门店公布的价格会存在一定差异，但价目表和声明并不构成要约。
            另外，如发现线上标示的产品信息存在错误的，您可以要求商家尽快跟您取得联络，根据双方达成的合意对订单内容（如有）进行修改，您有权根据法律法规的规定向商家主张合理补偿。
        </strong>但由于众所周知的网络技术因素等客观原因导致的线上信息滞后或差错的，希望用户对此情形予以理解。我们在发现门店或商家自行发现其显示的商品信息、描述存在明显错误、有重大瑕疵的情况下（例如基于系统、人为输入、填写错误等原因，出现商品价格显著低于平台内或其他类似行业中相同或类似商品的价格，或商品规格与其价格明显不匹配等），有权对该等信息进行撤回、对交易订单进行撤销。用户已支付价款的，全额价款应原路径返还至付款账户。
    </p>
    <p>为表述便利，商品和服务简称为"商品"或"货物"。</p>
    <p>2.商品促销</p>
    <p>2.1 用户同意天街喵有权在提供本服务过程中自行或由第三方广告商向用户发送广告、推广或宣传促销信息(包括商业与非商业信息)，其方式和范围无须向用户特别通知。</p>
    <p>2.2 天街喵有权为用户提供选择关闭广告信息的功能。</p>
    <p>2.3 在接受本服务过程中，用户将自行判断广告信息的有效性并为自己的判断行为负责。</p>
    <p>2.4 由于市场变化等因素的影响，本平台无法保证您接受的促销信息都为有效内容，实际情况以天街喵商家发布的促销信息为准。</p>
    <p>3.商品订单</p>
    <p>天街喵保留对产品订购数量的限制权。在提交订单的同时，用户也同时承认了其拥有购买这些产品的权利能力和行为能力，并且对订单中提供的所有信息的真实性负责。<strong>您通过天街喵下达订单时须填写您希望购买的商品数量、收货人姓名、联系方式、收货地址等内容，系统据此生成完整的要约，在您填入这些信息时即代表您授权我们将这些信息为履行本协议之目的向第三方及商家披露。
            请注意，如您填写的收货人非您本人，则该收货人的行为和意思表示产生的法律后果均由您承担。</strong>
        目标商家收到您的要约信息后有权结合商品库存、交易的真实性与合理性、是否损害其他会员的利益等因素，合理判断是否与您就本订单商品确认合同关系。合同关系一经成立，商品订单中所包含的全部内容即为商品合同的内容，您与商家均应当按照合同约定履行各自的权利义务。由于市场变化、人工拣货时差、系统数据误差等以合理商业努力难以控制的因素影响，特殊情况下，用户付款后存在库存不足、配送员运力不足或不可抗力等导致的履约不能或不便的可能；如用户拟购买的商品发生上述情形的，您与商家均有权取消订单，如您已付款的，交易价款将全额原路径返还至付款账户。您的购买行为应当基于真实的消费需求，不得存在对商品实施恶意购买、恶意维权、违规领取权益（如机器作弊、提供非真实）、恶意利用退款流程异常频繁的对无质量问题商品发起退款、免费套取赠品等其它优惠权益、在营销活动中购买商品达到参与条件后又恶意利用退款流程绕开规则套取权益等扰乱天街喵正常交易秩序的行为。如发现您或者您的要约涉嫌不真实、不诚信或滥用会员权利等违反本协议约定等情形的，商家有权拒绝您的要约。基于维护天街喵平台的交易秩序及交易安全的需要，发生上述情况时我们也可主动执行取消相关交易订单、收回活动权益及资格等违规处置，并有权向您追偿因此给我们造成的损失。<strong>除此之外，
            用户购买跨境商品的行为还应遵守法律法规及政策规定的购买限值规定以及遵守所有适用的进出口管制、贸易限制与经济制裁等相关法律法规及政策，如用户未遵守前述规定的，天街喵有权对用户订购数量进行限制或取消。</strong>
    </p>
    <p>4.商品售后</p>
    <p>用户在天街喵APP或小程序等交易后发生的售后问题，适用此规则。天街喵将通过系统通知、页面信息提示、短信或电话等方式向用户发送与售后处理相关的提示或通知，帮助您向商家主张售后服务，同时了解商家、商品的售后服务规则，您售后请求的处理进度等。该等提示或通知构成售后处理依据及结论的有效组成部分。
    </p>
    <p>4.1 售后受理时效</p>
    <p>（1）支持7天无理由退换货的商品应在收到商品之日起7天内提出退换货申请（自系统显示签收商品的次日零时起计算，满168小时为七天）。</p>
    <p>（2）不支持7天无理由退换货的商品仅发生质量问题或错漏发问题时支持退换货，用户应在收到商品之时起24小时内提出退换货申请。</p>
    <p>（3）实行国家"三包"政策的商品如出现国家法律规定的功能性障碍或质量问题，支持在收到商品之日起15天内提出换货申请。因购买的跨境商品等同于境外购买，本条款不适用于跨境商品。</p>
    <p>（4）超出本规则规定的售后时限发起的退换货申请无法受理。</p>
    <p>4.2 售后受理范围</p>
    <p>（1）当用户购买的商品发生质量问题、商品错发/漏发、商品破损、过期、描述不符等问题，支持退换货申请。 （2）参加限时抢购、换购、特价促销（清仓)的商品出现质量问题时因商品特性仅支持退货退款，暂不支持换货。</p>
    <p>（3）因客户保管储藏不当、人为操作不当导致商品无法食用/使用，不支持退换货。</p>
    <p>（4）对支持七天无理由退换货并符合完好标准的商品，可发起七天无理由退换货申请。</p>
    <p>退换货适用范围如下： 分类 类型 商品举例 "不支持七天无理由退货"的商品 一、定制类商品 、个性定制、设计服务 ；二、鲜活易腐类商品、
        鲜花绿植、新鲜蔬果、烘焙熟食、鲜奶、禽肉禽蛋、海鲜水产、冷藏食品、冷冻食品、水产肉类加工品、宠物等； 三、在线下载或激活的数字化商品： 电子券包 ；四、一经激活或者试用后价值贬损较大的商品，或非生活消费所需的商品
        智能设备、商用厨具、内裤、短袜/打底袜/丝袜/美腿袜、塑身裤、塑身连体衣、乳贴、插片/胸垫等 ；五、拆封后影响人身安全或生命健康的商品，或拆封后易导致商品品质发生改变的商品 食品、保健品、贴身用品等 跨境购商品
        所有跨境商品均不支持七天无理由退换货 "支持七天无理由退货"的商品 除上述类型外的其他商品 服装服饰（除贴身用品）、家纺居家日用、母婴用品（除食品、贴身用品）、宠物用品（除食品、药品）、家具、家电等。</p>
    <p>4.3 完好标准界定</p>
    <p>（1）普通完好标准界定
        A、商品能够保持原有品质、功能。用户应当确保退回商品和相关配（附）件（如吊牌、说明书、三包卡等）齐全，并保持原有品质、功能，无受损、受污、刮开防伪、产生激活（授权）等情形，无难以恢复原状的外观类使用痕迹、不合理的个人数据使用痕迹；
        B、用户基于查验需要而打开商品包装，或者为确认商品的品质、功能而进行合理的调试不影响商品的完好。对超出查验和确认商品品质、功能需要而使用商品，导致商品价值贬损较大的，视为商品不完好；
        C、商品包装作为商品的必要组成部分，其缺损状况易导致商品价值贬损，故退回时应确保商品包装完整。部分具有特定价值或功能的特殊商品包装，可依商品页面事前明示的包装保护要求处理。</p>
    <p>（2）特殊完好标准界定类目 特殊完好标准 鞋/服装 （若有）防盗扣、防损贴且不影响合理试用，不可剪掉、撕毁；无显著穿戴、无洗涤、无沾染化妆品（如口红）等痕迹 家具清洁 （若有）商品一次性密封包装未拆封 个人护理
        （若有）商品一次性密封包装未拆封 日用百货 （若有）商品一次性密封包装未拆封 数码大家电行业（除相机、大家电） 1. 进网许可证未损坏（毁） 2. 未有浸液 大家电 1. 液晶电视、空调允许简单通电调试，不得上墙安装使用
        2. 洗衣机不得过水使用 宠物食品、保健品 （若有）商品一次性密封包装未拆封 食品（含保健食品）、化妆品、计生用品、个人护理用品、成人用品、婴儿尿片外包装被一次性密封的，不得拆封。
        "密封"是指：商品外包装被生产厂商加以了封条（签）或整体塑（密）封的包装措施</p>
    <p>4.4 处理规则 4.4.1 商品质量问题、假冒商品认定 （1）若商品发生质量问题，系肉眼可识别的（如发霉、腐烂）： 用户应在有效售后时限内反馈并提供相应凭证（图片或视频），用户无法提供凭证的，不支持退换货。
        （2）用户反馈商品质量问题系肉眼不可见或系假冒商品的：A、用户应提供有效的质检凭证或其他有效凭证。用户无法提供的，质量问题或假冒商品认定无效；
        B、用户已提供相关凭证，对于非跨境购商品，天街喵将通过合理解释或提供厂家的经销凭证、报关单据（进口商品）、产品合格证、执行标准等相关凭证以证明商品来源或出厂合规；对于跨境购商品，由于跨境购商品等同于境外购买，适用原产地有关质量、安全、卫生、环保、标识等标准或技术规范要求，天街喵将提供报关单据、厂家经销凭证、保税仓相关信息以证明商品来源或出产合规；C、若确认出售的商品为质量问题，提供退货退款服务；若确认出售的商品系假冒商品，用户基于生活消费所需购买的，如涉及跨境购商品，还应在法律法规及政策规定的购买限值之内，提供退货退款服务（生活消费所需的认定：天街喵将依照普通人的认知水准及日常经验判断，或基于概率学和大数据技术，从交易主体、交易信息、交易行为等多个维度进行排查、综合判断用户是否属生活消费所需购买。）
        （3）用户应保管申请退换货的商品，如有必要，天街喵或天街喵委托的物流公司将联系用户上门取回商品后进行退货退款处理。 4.4.2 描述不符问题处理规范
        （1）用户申请售后的商品存在描述不当系肉眼可识别的，用户应提供凭证予以证明，用户无法提供凭证的，不支持退换货。用户已提供凭证，天街喵针对用户给出的凭证确认描述不符属实的，提供退货退款服务。
        （2）商品的描述不当系无需使用肉眼即可显著识别的，若商品完好，支持退货退款；若用户已经使用且影响商品完好的，不支持退货退款。 （3）生鲜类商品（新鲜蔬果、肉禽鱼类）存在重量短缺情况时，按短缺部分作相应比例部分退款处理。
        4.4.3 签收问题处理规范 （1）用户填写的订单收件信息应真实、详细、准确、有效。因提供的收件信息错误导致延迟或者未收到商品的，用户需承担该责任限度内的不利后果。
        （2）订单发货后，收件人应亲自签收商品。收件人委托他人签收商品或已按收件人的指示将商品置于指定地点的，视为收件人本人签收。
        （3）用户无正当理由拒绝签收不支持七天无理由退货且性质不适宜拒签的商品，商品返回门店后，若商品性质已变化，影响二次销售，用户应承担由此产生的损失。4.4.4 其他处理细则
        （1）若用户购买的商品有赠品或换购商品，办理退货时，须同时将赠品/换购商品以及将商品的内带附件、保修卡、说明书、发票、检测报告等随同商品全部退回。赠品/换购商品遗失或破损不影响商品退货，赠品/换购商品以及将商品的内带附件、保修卡、说明书、发票、检测报告等破损或遗失作折价处理，退款时需扣除相应金额。
        （2）办理整单退货退款时，已开具的发票将进行自动红冲；办理部分退货退款时，系统将根据退货后的有效商品金额自动重新开具新的发票，原发票自动红冲。因购买的跨境商品等同于境外购买，无法开具境内发票，故本条款不适用于跨境商品。
        （3）商品一经送出即产生配送费，发生7天无理由退货时，已支付的配送费不支持退回。 （4）办理部分退货退款时，已使用的积分将按比例退还，优惠券、第三方支付优惠系统不支持退回。
        （4）办理整单退货退款时，已使用的积分将按比例退还，优惠券支持退回，第三方支付优惠不支持退回。因质量问题发生的退货退款，优惠券退回日期若超过券的限用日期，支持补发。
        （5）用户未申请售后或审核不通过擅自寄回商品的，造成的货物丢失、邮寄地址错误等问题产生的损失需由用户承担，且天街喵有权向用户收取仓储综合费用。</p>
    <p>5.第三方服务说明</p>
    <p>本协议适用于添丽网络以天街喵App客户端+天街喵小程序等以及随技术发展出现的新形态向您提供的各项产品和服务。如我们及关联公司的产品或服务中使用了天街喵的产品或服务（如使用天街喵会员账户登录）但未设独立用户协议的，则本政策同样适用于该部分产品或服务。
    </p>
    <p>除本协议说明的相关信息收集使用活动外，本协议不适用于天街喵商家及第三方向您提供的其他服务，其他服务适用其向您另行说明的隐私政策等个人信息收集使用规则。</p>
    <p>三、合作伙伴</p>
    <p>天街喵只选择信誉良好的企业或本身即是天街喵特约商户的公司为合作伙伴（以下简称合作伙伴），当用户通过天街喵获得合作伙伴的优惠服务时，请事先详阅优惠细则，因用户与合作伙伴产生的任何交易、服务发生纠纷时，应由用户与该合作伙伴自行协商解决，天街喵不承担任何责任。
        天街喵第三方场景使用，以下或简称"第三方场景使用功能"，是指在您同意本协议的前提下，您可以在第三方服务商的APP、手机厂商系统的软硬件（以下简称"第三方场景"）内使用天街喵消费购物功能及服务。
        在开通第三方场景使用功能之前，您应当注意以下事项并承诺：您有意愿在第三方场景中使用天街喵购物车服务；同意第三方场景提供方将相关信息提供、同步给我们或我们将相关信息提供、同步绐第三方场景提供方；您认可第三方场景的软硬件安全管控机制，将按照其与您的协议约定履行用户安全注意义务。
    </p>
    <p>在开通第三方场景使用功能之后，您理解并认可如下服务：</p>
    <p>（1）可在第三方场景中查看到天街喵购物服务以及相关信息，包括天街喵账号（邮箱/手机）、头像、昵称、订单信息等。</p>
    <p>（2）可在第三方场景中使用购物服务，包括信息查询、位置服务、第三支付服务等，以及由此引起与第三方场景之间的信息同步。</p>
    <p>（3）可在天街喵客户端注销天街喵帐号，注销后您将无法在第三方场景中查看、使用天街喵购物功能、查询订单信息或开具发票。</p>
    <p>在使用第三方场景功能时，您理解并认可以下事项：</p>
    <p>（1）第三方服务商是第三方场景的独立运营方并承担责任。您应当妥善使用、保管第三方场景的软硬件；如发生第三方场景软硬件的软件故障、硬件丢失、系统安全漏洞等情形，将可能导致您产生经济损失。出现前述情形，请您及时联系第三方服务商沟通解决。
    </p>
    <p>（2）第三方场景软硬件的技术、系统、网络等方面的稳定性，可能会造成您使用购物服务时，出现页面迟延、信息同步不及时或无法使用的情况，请您耐心等待或在天街喵客户端內使用购物服务。
        第三方场景使用功能实现以天街喵与第三方服务商的合作为基础，若天街喵与第三方服务商终止合作，第三方场景使用功能将自动终止。天街喵可以视第三方场景使用的用户体验情况，对产品功能进行升级、迭代更新或终止、下线。</p>
    <p>四、用户权利与义务</p>
    <p>用户有权拥有其在天街喵的账户，并有权使用自己的手机号码随时登录天街喵。用户不得以任何形式转让或授权他人使用自己的天街喵用户名，否则由此产生的一切后果由用户自行承担。
        用户有权根据本协议的规定以及天街喵上发布的相关规则在天街喵上查询商品信息、发表使用体验、参与商品讨论、邀请关注好友、合法上传商品图片、发表社区言论等参加天街喵的有关活动以及有权享受天街喵提供的其它的有关信息服务。
        用户应当保证在使用天街喵购买商品过程中遵守诚实信用原则，不在购买过程中采取不正当行为，不扰乱平台上交易的正常秩序。
        用户不得在天街喵发表包含以下内容的言论，否则天街喵有权随时删除相关言论：（1）煽动、抗拒、破坏宪法和法律、行政法规实施的；
        （2）煽动颠覆国家政权，推翻社会主义制度的；（3）煽动、分裂国家，破坏国家统一的；（4）煽动民族仇恨、民族歧视，破坏民族团结的；（5）任何包含对种族、性别、宗教、地域内容等歧视的；（6）捏造或者歪曲事实，散布谣言，扰乱社会秩序的；（7）宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；（8）公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；（9）损害国家机关信誉的；（10）其他违反宪法和法律行政法规的。
        用户在发表使用体验、讨论图片等，除遵守本条款外，还应遵守家门口天街喵的相关规定。未经我们同意，用户不得在网站发布任何形式的广告。否则，我们有权随时予以删除。</p>
    <p>五、天街喵的权利和义务</p>
    <p>天街喵有义务在现有技术上维护整个交易平台的正常运行，并努力提升和改进技术，使用户在平台上交易活动得以顺利进行。 对用户在注册使用天街喵交易平台中所遇到的与交易或注册有关的问题及反映的情况，天街喵将及时作出回复。
        对于用户在天街喵上的不当行为或其它任何天街喵认为应当终止服务的情况，天街喵有权随时作出删除相关信息、终止提供服务等处理，而无须征得用户的同意。 用户在此授予天街喵独家的、全球通用的、永久的、免费的许可使用权利
        (并有权对该权利进行再授权)，使天街喵全部或部分地使用、复制、修订、改写、发布、翻译、分发、执行和展示用户公示于天街喵的各类信息或制作其派生作品，或以现在已知或日后开发的任何形式、媒体或技术，将上述信息纳入其它作品内。
    </p>
    <p><strong>为适应电子商务发展和满足海量用户对高效优质服务的需求，打击黑灰产业，您理解并同意，天街喵可在平台规则中约定违约认定的程序和标准。如，平台可依据您的用户数据与海量用户数据的关系来认定您是否构成违约；您有义务对您的数据异常现象进行充分举证和合理解释，否则将被认定为违约，同时适用本协议"异常账户""高风险交易"等限制性约定。</strong>
    </p>
    <p>六、隐私保护</p>
    <p>天街喵注重保护用户的个人信息及个人隐私。在您使用我们提供的服务时，您同意我们按照隐私权政策收集、储存、使用、披露和保护您的个人信息。我们不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可。但是，天街喵可能会向合作伙伴等第三方共享你的个人信息，以保障为您提供的服务顺利完成，并且改善和向用户提供更好的服务，但天街喵仅限于合法、正当、必要、特定、明确的目的共享您的个人信息。天街喵的合作伙伴为商品和技术服务供应商，天街喵可能会将与您的个人信息有关的用户数据共享给支持天街喵功能的的第三方进行分析和优化。我们希望通过隐私权政策向您清楚的介绍天街喵对您个人信息的处理方式，因此我们诚恳地建议您完整阅读隐私权政策，以帮助您更好的保护您的隐私权。
    </p>
    <p>七、服务的中断和终止</p>
    <p>用户认可，天街喵可能根据业务调整而变更向您提供软件许可及平台服务的主体，变更后的主体与您共同履行本协议并向您提供服务，基于以上变更并不常见而且并不会影响您本协议项下的权益的情形下，我们对此不再另行通知。用户对本协议的修改有异议，或对天街喵的服务不满，可以行使如下权利：
    </p>
    <p>（1) 停止使用天街喵的网络服务；</p>
    <p>（2) 通过客服等渠道要求天街喵停止对其服务。</p>
    <p>八、适用的法律与管辖权</p>
    <p>用户与天街喵之间的协议将适用中华人民共和国的法律。您因使用天街喵平台服务所产及与平台服务有关的争议，由添丽网络与您协商解决。协商不成的任何一方均可向被告所在地的人民法院提起诉讼。</p>
    <p>九、其他</p>
    <p>如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
    <p><br></p>
    <p><br></p>
    <h1 class="ql-align-center"><br></h1>
    </div>
</template>
  
<script>

export default {
    components: {
    },

    data() {
        return {

        };
    },

    created() {
    },
    mounted() { },
    beforeMount() { },
    methods: {


    },
};
</script>
  
<style lang="less" scoped></style>